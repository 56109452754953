import React from "react";
import NavPage from "components/NavPage";
import { ContributorsList } from "components/ContributorsList";
import { Helmet } from 'react-helmet';

export function Contributors() {
    return (
        <NavPage headerGradientOnly={true}>
            <Helmet>
                <title>TA NA KA Typeclub: Contributors</title>
                <meta name="description" content="Tanaka Typeclub's font creators: the people who make all of the fonts distributed here." />
            </Helmet>
            <ContributorsList />
        </NavPage>
    );
}