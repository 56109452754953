import React from "react";
import { Helmet } from 'react-helmet';
import NavPage from "components/NavPage";

import './Thanks.scss';

export function DonationThanks() {    
    return (
        <NavPage>
            <Helmet>
                <title>TA NA KA Typeclub: Thanks!</title>
                <meta name="description" content="Tanaka Typeclub thanks you for supporting independent type creators!" />
            </Helmet>
            <div className="thanksTile">
                <h2>Thanks!</h2>
                <p>
                    Thank you so much for donating to Tanaka Typeclub. Your generosity will help make it possible
                    for us to keep supporting independent type creators.
                </p>
            </div>
        </NavPage>
    );
}