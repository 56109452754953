import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { BasicDropdownToggle } from "./BasicDropdownToggle";
import { DropdownItem } from "./DropdownItem";

import './navMenu.scss';

export class NavMenu extends React.Component {
    render() {
        return (
            <div className="navMenuContainer">
                <Dropdown>
                    <Dropdown.Toggle as={BasicDropdownToggle} id="navDropdown" onToggle={this.onDropdownToggle}>
                        MENU
                    </Dropdown.Toggle>

                    <Dropdown.Menu popperConfig={{style: {left: "-10px"}}}>
                        <Dropdown.Item as={DropdownItem}><Link to="/">Home</Link></Dropdown.Item>
                        <Dropdown.Item as={DropdownItem}><Link to="/fonts">Fonts</Link></Dropdown.Item>
                        <Dropdown.Item as={DropdownItem}><Link to="/contributors">Contributors</Link></Dropdown.Item>
                        <Dropdown.Item as={DropdownItem}><Link to="/about">About</Link></Dropdown.Item>
                        <Dropdown.Item as={DropdownItem}><Link to="/faq">FAQ</Link></Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    }
};