import React from "react";
import { Helmet } from 'react-helmet';
import NavPage from "components/NavPage";

import './FrequentlyAskedQuestions.scss';

export function FrequentlyAskedQuestions() {
    return (
        <NavPage headerGradientOnly={true}>
            <Helmet>
                <title>TA NA KA Typeclub: Frequently Asked Questions</title>
                <meta name="description" content="Answers to frequently asked questions about Tanaka Typeclub, how it works, etc." />
            </Helmet>
            <div className="faqPage">
                <h2>Frequently Asked Questions</h2>
                <div className="questions">
                    <a href="#personalLicense">What is the function of a personal license?</a>
                    <a href="#getTypeface">How do I get my typeface?</a>
                    <a href="#fontCase">
                        Why does my font only have upper and lower case
                        or why does my font have missing punctuation?
                    </a>
                </div>
                <div className="answers">
                    <div className="answer" id="personalLicense">
                        <h5>What is the function of a personal license?</h5>
                        <p>
                            The personal license functions as a way for you (the designer) to pay
                            for the typeface of your choice. For example: using this license enables
                            usage for client mockups or personal business cards and/or personal website.
                            If the typeface will be used for resale- i.e. a logo design for a client,
                            then you will need to re-purchase the typeface at the professional license
                            fee and this cost should be absorbed by the client.
                        </p>
                    </div>
                    <div className="answer" id="getTypeface">
                        <h5>How do I get my typeface?</h5>
                        <p>
                            Select the fabulous font you want to download- choose your type of license; 
                            click the download button that directs you to PayPal- where you will directly
                            purchase the font from the designer. Once the payment is complete, the license
                            is activated, and you are ready to use your new typeface and/or buy more!
                        </p>
                    </div>
                    <div className="answer" id="fontCase">
                        <h5>
                            Why does my font only have upper and lower case
                            or why does my font have missing punctuation?
                        </h5>
                        <p>
                            These are student designed fonts and fall under the category of DISPLAY FONTS.
                            Display fonts traditionally are not used for body copy and therefore are not
                            subject to the type rules applied to body copy. Usually a Display typeface has
                            just one family member, i.e. Merengue Puff has one family member. We do have
                            several display typefaces that do have family members; please look at LURCH,
                            BLOCK BOT, and KINGS AND QUEENS. What this means is that you can have a typeface
                            that is unicase or a typeface that is all caps or all lower case. You may not
                            have a typeface that has some of the standard punctuation marks as they may have
                            been deemed unnecessary to the type designer. If you need to have a glyph that
                            is missing from the font, please feel free to contact the type designer and ask
                            them if they may accommodate your needs.
                        </p>
                    </div>
                </div>
            </div>
        </NavPage>
    );
}