// Generates a PayPal link to redirect to for payment. example:
// https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=someone%40example.com&currency_code=USD&amount=10&source=url&return=
export const createPaypalLink = (paypalEmail, nameOfItem, amountInDollars, urlToReturnTo) => {
    const uriBase = 'https://www.paypal.com/cgi-bin/webscr?cmd=_donations&currency_code=USD&source=url';
    const email = `&business=${encodeURIComponent(paypalEmail)}`;
    const amount = `&amount=${amountInDollars}`;
    const itemName = `&item_name=${nameOfItem.replace(/ /g, "+")}`;
    const returnUrl = `&return=${urlToReturnTo}`;
    return `${uriBase}${email}${amount}${itemName}${returnUrl}`;
};

const sanitizeDonationAmount = (amount) => {
    const noDollars = amount.replace(/\$/g, '');
    const noLetters = noDollars.replace(/[a-zA-Z]/g, '');
    const parsed = parseInt(noLetters);
    return isNaN(parsed) || parsed < 1 ? 1 : parsed;
};

export const redirectToDonation = (donationAmount = 1) => {
    const safeDonationAmount = sanitizeDonationAmount(donationAmount);
    const redirectUrl = `${window.location.origin}/tanaka_thank_you`;
    const paypalEmail = 'tanakabonita@gmail.com';
    const uriBase = 'https://www.paypal.com/cgi-bin/webscr?cmd=_donations&currency_code=USD&source=url';
    const email = `&business=${encodeURIComponent(paypalEmail)}`;
    const amount = `&amount=${safeDonationAmount}`;
    const itemName = `&item_name=Tanaka+Type+Club+Donation`;
    const returnUrl = `&return=${redirectUrl}`;
    const redirect = `${uriBase}${email}${amount}${itemName}${returnUrl}`;
    window.location = redirect;
};