import React from "react";
import PropTypes from "prop-types";
import { Background } from "./Background";
import { Header } from "./Header";
import { Footer } from "./Footer";

import './NavPage.scss';

const propTypes = {
    headerGradientOnly: PropTypes.bool,
};

const defaultProps = {
    headerGradientOnly: false,
};

const NavPage = (props) => {
    return (
        <div className="navPage">
            <Background headerGradientOnly={props.headerGradientOnly} />
            <Header hasNav={true} />
                <div className="pageBody">
                    {props.children}
                </div>
            <Footer />
        </div>
    );
};

NavPage.propTypes = propTypes;
NavPage.defaultProps = defaultProps;

export default NavPage;