import React from "react";

import './Background.scss';

export class Background extends React.Component {
    render() {
        const { headerGradientOnly } = this.props;
        const position = Math.floor(Math.random() * 100);
        const backgroundPosition = `${position}% 50%`;
        const panelColorClassNames = headerGradientOnly
            ? "panelColor limitedPanelColor"
            : "panelColor"
        return (
            <div className="backgroundPositioner">
                <div className="backgroundContainer">
                    <div className={panelColorClassNames} style={{backgroundPosition}}></div>
                    {!headerGradientOnly && <div className="panelFade"></div>}
                </div>
            </div>
        );
    }
};