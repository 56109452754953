export const fonts = [
    // {
    //     font_name: "Helvetica",
    //     author_name_full: "Max Miedinger",
    //     author_name_last: "Miedinger",
    //     author_bio_image: "max_bio.png",
    //     author_bio_text: "This guy created Helvetica. How awesome!",
    //     author_linkedin: "",
    //     author_paypal_email: "helvetica@gmail.com",
    //     author_shop_website: "https://www.society6.com/asdf",
    //     author_website: "http://www.helvetica.com",
    //     categories: ["Sans-serif", "Modern"],
    //     description: "Perhaps the most ubiquitous modern typeface.",
    //     font_download: "helvetica.zip",
    //     font_family: "Helvetica",
    //     font_notes: "This font contains punctuation",
    //     poster_alternates: ["helvetica_cool_design.png"],
    //     poster_large: "helvetica_large.png",
    //     poster_small: "helvetica_small.png",
    //     poster_link: "https://society6.com",
    //     size_small: "10px",
    //     size_medium: "14px",
    //     size_large: "18px",
    //     size_xlarge: "24px",
    //     styles: [
    //         {
    //             style_name: "normal",
    //             font_family: "Helvetica"
    //         },
    //         {
    //             style_name: "bold",
    //             font_family: "Helvetica-bold"
    //         },
    //         {
    //             style_name: "italic",
    //             font_family: "Helvetica-italic"
    //         },
    //     ],
    //     tanaka_rank: 1,
    // },
    {
        font_name: "Kidney Bean",
        author_name_full: "Philip Oakley Otto",
        author_name_last: "Otto",
        author_bio_image: "kidney_bean_bio_pic.png",
        author_bio_text: "I’m an Artist and Graphic Designer currently studying Design Media Arts at UCLA. I enjoy thinking about how things are made and living with my wife and cat in Santa Monica.",
        author_linkedin: "",
        author_paypal_email: "philip@philipotto.com",
        author_shop_website: "",
        author_website: "http://philipotto.com",
        categories: ["serif", "unique", "playful"],
        description: " Inspired by the curved organic form of the legume; Kidney Bean is a modern take on an inscribed font, with a touch of whimsy. Born partly out of chisel and partly out of stylus, Kidney Bean is equally at home rendered in pixels or set in stone.",
        font_download: "kidney_bean.zip",
        font_family: "kidney_bean",
        font_notes: "",
        font_supported_case: "upper",
        poster_alternates: ["kidney_bean_small_poster_02.png"],
        poster_large: "kidney_bean_small_poster_01.png",
        poster_small: "kidney_bean_small_poster_01.png",
        poster_link: "",
        size_small: "10px",
        size_medium: "14px",
        size_large: "22px",
        size_xlarge: "32px",
        styles: [],
        tanaka_rank: 5,
    },
    {
        font_name: "Meringue Puff",
        author_name_full: "Kris Choi",
        author_name_last: "Choi",
        author_bio_image: "meringue_puff_bio.png",
        author_bio_text: "I am a graphic designer based in LA. In my free time, I love to cook, write, and play with my cat Paul. I am currently a student at SMC.",
        author_linkedin: "",
        author_paypal_email: "curoeori@gmail.com",
        author_shop_website: "",
        author_website: "krisc0000@gmail.com",
        categories: ["Sans-serif", "rounded", "playful"],
        description: "Meringue Puff is a unicase display typeface. It consists of 26 Latin alphabets, numbers and additional 19 glyphs. Inspired by a historic typeface Bodoni Poster, Meringue Puff features strong strokes, slim hairlines, uniform cap heights, and curvy terminals. While maintaining readability, each letter manages to have its unique, expressive hand-drawn shapes.",
        font_download: "meringue_puff.zip",
        font_family: "meringue_puff",
        font_notes: "",
        font_supported_case: "upper",
        poster_alternates: ["meringue_puff_large_02.jpg", "meringuepuff_black_white.png"],
        poster_large: "meringue_puff_large_01.jpg",
        poster_small: "meringue_puff_small_01.png",
        poster_link: "",
        size_small: "10px",
        size_medium: "14px",
        size_large: "32px",
        size_xlarge: "44px",
        styles: [],
        tanaka_rank: 6,
    },
    {
        font_name: "Tinker",
        author_name_full: "Soph Fuchs",
        author_name_last: "Fuchs",
        author_bio_image: "fake_tinker_bio_pic.png",
        author_bio_text: "Soph Fuchs is a UX and Graphic Designer, who likes to take a holistic approach to design.",
        author_linkedin: "",
        author_paypal_email: "",
        author_shop_website: "",
        author_website: "http://tanakatypeclub.com",
        categories: ["Sans-serif", "rounded", "playful"],
        description: "Tinker was made because of Soph’s value of play. Tinker’s letters are shaped along the guides of typewriter keys. Keeping the monospace I love in courier while ditching the sterility. This clever minx has grander ambitions than square slabs. As should you. Lay some Tinker on your latest poster-or, see how it feels selling tacos on your author_website, Fuck around a little and see what inspires you.",
        font_download: "tinker.zip",
        font_family: "tinker",
        font_notes: "",
        font_supported_case: "all",
        poster_alternates: ["Tinker_poster_large_Page_06.png", "Tinker_poster_large_Page_12.png", "Tinker_poster_large_Page_15.png"],
        poster_large: "tinker_poster_large_Page_01.png",
        poster_small: "tinker_poster_small.png",
        poster_link: "https://society6.com",
        size_small: "10px",
        size_medium: "14px",
        size_large: "26px",
        size_xlarge: "48px",
        styles: [],
        tanaka_rank: 7,
    },
    {
        font_name: "illsans",
        author_name_full: "Chioma Ojini",
        author_name_last: "Ojini",
        author_bio_image: "chioma-bio-photo.png",
        author_bio_text: "I’m an LA-based Interaction Designer and social media manager who recently graduated from the SMC IxD Bachelor program. In my free time, I love to sing and produce electronic music.",
        author_linkedin: "https://www.linkedin.com/in/chiomaojini/",
        author_paypal_email: "ojinichi@gmail.com",
        author_shop_website: "",
        author_website: "http://chiomaojini.com",
        categories: [""],
        description: "I love the Memphis style that came out of Italy in the 80's. Memphis Group used colorful palates and geometric structures to offer a post-modernist, rule-breaking, and sometimes repulsive approach to architectural design. Using Gills Sans as my base from which to work, I started mapping sections of typeface, peeling away at the strokes, reducing and enlarging the stroke weights, and forming notable contrasts. What remains is ill sans. The design was completed in May 2017 and is fully functional OTF file ready for download and your next great project!",
        font_download: "illsans.zip",
        font_family: "illsans",
        font_notes: "",
        font_supported_case: "lower",
        poster_alternates: ["illsans_small_blk.png"],
        poster_large: "ill_sans_poster_large.png",
        poster_small: "illsans_poster_small.png",
        poster_link: "",
        size_small: "10px",
        size_medium: "14px",
        size_large: "26px",
        size_xlarge: "50px",
        styles: [],
        tanaka_rank: 10,
    },
    {
        font_name: "LURCH",
        author_name_full: "Alejandro (LJNDR) Rincon",
        author_name_last: "Rincon",
        author_bio_image: "lurch_bio.jpg",
        author_bio_text: "Hello everyone! My name is Alejandro, & I am a full time student at Santa Monica College pursuing a degree in Graphic Design. My interests include art, food, & napping (in that order).",
        author_linkedin: "",
        author_paypal_email: "alejandrorincon50@gmail.com",
        author_shop_website: "",
        author_website: "",
        categories: [""],
        description: "Bold strokes, tall cap heights, & extremely low x-heights; these are the elements used to create Lurch. This decorative/display font was created by Alejandro Rincon. This font cake about while he was watching America’s favorite gothic family, The Addams Family. Intrigued by the family’s butler, he went about creating this uniform typeface that resembled this giant box-shaped “monster”. This type face is best used when working with headings, or titles, at a large point size. As this typeface hovers around those around him, this unique font will sure stand out from the rest. ",
        font_download: "lurch.zip",
        font_family: "lurch",
        font_notes: "",
        font_supported_case: "upper",
        offset_top_large: "8px",
        poster_alternates: [""],
        poster_large: "lurch_regular_large.png",
        poster_small: "lurch_small.png",
        poster_link: "",
        size_small: "10px",
        size_medium: "14px",
        size_large: "36px",
        size_xlarge: "52px",
        styles: [],
        tanaka_rank: 4,
    },
    {
        font_name: "Le Cirq",
        author_name_full: "Alan Chung",
        author_name_last: "Chung",
        author_bio_image: "leCirq_bio.png",
        author_bio_text: "I'm a designer in Los Angeles; Otis College of Art & Design c/o 2020. My days off are spent cooking, playing/watching basketball, and playing video games.",
        author_linkedin: "",
        author_paypal_email: "alanjchung@gmail.com",
        author_shop_website: "",
        author_website: "http://www.alanjchung.com",
        categories: [""],
        description: "Le Cirq is a typeface that was born out of a return to one of the most basic of visual elements in an effort to go against the grain. This display type eschews the details of a traditionally beautiful typeface and sits comfortably in a room with no chairs, metaphorically speaking.",
        font_download: "leCirq.zip",
        font_family: "le_cirq",
        font_notes: "",
        font_supported_case: "upper",
        poster_alternates: [""],
        poster_large: "le_cirq_poster_large.png",
        poster_small: "le_cirq_small.png",
        poster_link: "",
        size_small: "10px",
        size_medium: "14px",
        size_large: "18px",
        size_xlarge: "28px",
        styles: [],
        tanaka_rank: 8,
    },
    // {
    //     font_name: "Blockbot",
    //     author_name_full: "Daniel Gonzalez",
    //     author_name_last: "Gonzalez",
    //     author_bio_image: "blockbot_bio.png",
    //     author_bio_text: "Daniel Gonzalaez is a Graphic Design student at Santa Monica College, he created Blockbot in 2019 as part of a typography assignment. The font was inspired by rectangles and clean lines.",
    //     author_linkedin: "",
    //     author_paypal_email: "danielgon2792@gmail.com",
    //     author_shop_website: "",
    //     author_website: "",
    //     categories: [""],
    //     description: "Blockbot was created by graphic designer Daniel Gonzalez, in 2019. The font was inspired by rectangles and clean lines; despite being very structured the font is fun and can be quirky at times. When Gonzalez created the font he only wanted to use horizontal and vertical lines, no curves or diagonal lines were used; which help give the font its futuristic feel. This design is meant to be used as a decorative typeface and works best on a larger scale. Blockbot consist of five type families which include bold, condensed, regular, thin and ultrabold.",
    //     font_download: "blockbot.zip",
    //     font_family: "blockbot",
    //     font_notes: "",
    //     font_supported_case: "upper",
    //     poster_alternates: [""],
    //     poster_large: "block_bot_large.png",
    //     poster_small: "block_bot_small.png",
    //     poster_link: "",
    //     size_small: "10px",
    //     size_medium: "14px",
    //     size_large: "18px",
    //     size_xlarge: "24px",
    //     styles: [],
    //     tanaka_rank: 9,
    // },
    {
        font_name: "Antoni",
        author_name_full: "Andrew Boquiren",
        author_name_last: "Boquiren",
        author_bio_image: "antoni_bio.png",
        author_bio_text: "Born in Manila, raised in LA, and educated in Southern California. Looking at the world through an architectural lens.",
        author_linkedin: "https://www.linkedin.com/in/andrew-boquiren-a567417/",
        author_paypal_email: "andrew.boquiren@gmail.com",
        author_shop_website: "",
        author_website: "https://andrewboquiren.myportfolio.com",
        categories: [""],
        description: "Antoni was created by Los Angeles based designer, Andrew Boquiren in 2019. The swooping shapes of Antoni are based on bold typefaces like Gastromond (JTD) and Masqualero (Monotype) which are thick and chunky, with heavy vertical stems, a spur on the q, and oblong counters. He also takes queues from more calligraphic and humanistic fonts like Viktor (OH no Type Co.). But much like Antoni’s creator, he has his background in architecture, being named after the Spanish architect, Antoni Gaudi. Freeform shapes, like those seen at the Portico of the Washerwoman in Park Guell, are mimicked in the open apertures in some of the characters. While most of Gaudi’s works are organically inclined, Antoni is structured, yet loose. He works well as a display font where his fine details can go noticed.",
        font_download: "antoni.zip",
        font_family: "antoni",
        font_notes: "",
        font_supported_case: "all",
        poster_alternates: ["antoni_poster_large_02.png", "antoni_poster_large_01.png", "antoni_poster_large_04.png", "antoni_poster_large_05.png"],
        poster_large: "antoni_poster_large_03.png",
        poster_small: "antoni_small_poster.png",
        poster_link: "",
        size_small: "10px",
        size_medium: "14px",
        size_large: "32px",
        size_xlarge: "46px",
        styles: [],
        tanaka_rank: 11,
    },
    {
        font_name: "Horizon West",
        author_name_full: "Bridgette Ricketts",
        author_name_last: "Ricketts",
        author_bio_image: "horizon_west_bio.png",
        author_bio_text: "Graphic Designer from Los Angeles. I offer creative collaboration with Graphic Design, Brand Identity, Art Direction, Logo Design, and Packaging design. Connect with me and let's make something beautiful!",
        author_linkedin: "https://www.linkedin.com/in/andrew-boquiren-a567417/",
        author_paypal_email: "bridgettericketts@gmail.com",
        author_shop_website: "",
        author_website: "http://bridgettericketts.com",
        categories: [""],
        description: "A slab font reminiscent of the Wild West",
        font_download: "horizonWest.zip",
        font_family: "horizon_west",
        font_notes: "",
        font_supported_case: "upper",
        poster_alternates: [""],
        poster_large: "Horizon_West_large.png",
        poster_small: "horizonWest_small.png",
        poster_link: "",
        size_small: "10px",
        size_medium: "14px",
        size_large: "22px",
        size_xlarge: "34px",
        styles: [],
        tanaka_rank: 1,
    },
    {
        font_name: "contranect",
        author_name_full: "Ilya Monroe",
        author_name_last: "Monroe",
        author_bio_image: "IlyaMonroe.png",
        author_bio_text: "I’m an LA-based Graphic Designer.",
        author_linkedin: "https://www.linkedin.com/in/ilya-monroe/",
        author_paypal_email: "thisilya@gmail.com",
        author_shop_website: "",
        author_website: "",
        categories: [""],
        description: "Inspired by contrast. Two typefaces connect and disconnect. They come together and break apart over and over again until they get it right. Based on the typefaces Egyptian and Raleway.",
        font_download: "Contranect.zip",
        font_family: "contranect",
        font_notes: "",
        font_supported_case: "lower",
        poster_alternates: [""],
        poster_large: "ContranectPoster_large.png",
        poster_small: "contranect_small.png",
        poster_link: "",
        size_small: "10px",
        size_medium: "14px",
        size_large: "32px",
        size_xlarge: "48px",
        styles: [],
        tanaka_rank: 2,
    },
    {
        font_name: "Kings And Queens",
        author_name_full: "Ryan Doyle",
        author_name_last: "Doyle",
        author_bio_image: "ryan_doyle.png",
        author_bio_text: "Professionally, I am a tutor and poker player. However, I have a strong passion for design, which manifests in almost every facet of my life and has led me to become part of the SMC IxD Bachelor’s program (class of ’21).",
        author_linkedin: "https://www.linkedin.com/in/ryan-doyle-tutor/",
        author_paypal_email: "ryanchrisdoyle@gmail.com",
        author_shop_website: "",
        author_website: "http://www.ryandoyletutor.com",
        categories: [""],
        description: "Much of the inspiration I needed to design 'Kings and Queens' came from one of my favorite designed objects: playing cards. Today, playing cards have a wonderfully large breadth of style. However, I chose to highlight the DNA and (mainstream) cultural presence of playing cards. My goal was to give you in a typeface, what you might find on a mob-run casino floor. Using “Busorama” and “Boutique Engraved” as jumping off points, I narrowed in on a few key attributes that were massaged and integrated into nearly every character: thin set widths, line and/or rotational symmetry, and notable stroke modulation. The typeface was completed in June 2019 and is a fully functional OTF file ready for download and your next great project!",
        font_download: "kingsandqueens.zip",
        font_family: "kings_and_queens",
        font_notes: "",
        font_supported_case: "",
        poster_alternates: [""],
        poster_large: "kingsandqueens_large.png",
        poster_small: "kingsandqueens_small.png",
        poster_link: "",
        size_small: "10px",
        size_medium: "14px",
        size_large: "32px",
        size_xlarge: "46px",
        styles: [],
        tanaka_rank: 3,
    },
    { 
        font_name: "Foxtale",
        author_name_full: "Gabe Silverstein",
        author_name_last: "Silverstein",
        author_bio_image: "fox_tail.png",
        author_bio_text: "I have been immersed in the world of design since early adolescence: Fascinated by automobile logos,movie posters, and branding - I have often been accused of being a design-head, a &quot;computer nerd&quot; -always crafting, creating, tinkering and improving.",
        author_linkedin: "https://www.linkedin.com/in/gabesilverstein/",
        author_paypal_email: "gabesilverstein@gmail.com",
        author_shop_website: "https://www.behance.net/gabesilverstein/moodboards",
        author_website: "http://www.gabesilverstein.com/",
        categories: [""],
        description: "Developed in the Summer of 2019 by Gabe Silverstein, 'Foxtale' was inspired by an ancient Hebraic scriptural style, boasting flared seraphs, classic structures and dramatic shapes. Foxtale is perfectly suited for impactful logo marks or subtext. A fully featured typeface with upper and lowercase, numbers and symbols - foxtale will surely excite your next design adventure!",
        font_download: "foxtale.zip",
        font_family: "foxtale",
        font_notes: "",
        font_supported_case: "",
        poster_alternates: [""],
        poster_large: "fox_tale_large.png",
        poster_small: "fox_tale_small.png",
        poster_link: "",
        size_small: "10px",
        size_medium: "14px",
        size_large: "32px",
        size_xlarge: "46px",
        styles: [],
        tanaka_rank: 12,
    },
    { 
        font_name: "Tacked",
        author_name_full: "Ross Meredith",
        author_name_last: "Meredith",
        author_bio_image: "ross_bio.png",
        author_bio_text: "I am an aspiring Interaction Design professional with a myriad of experience including graphic design, spatial design, photography, projection mapping, user interface, branding, and packaging design. I stay busy managing archival footage and designing chalkboard art.",
        author_linkedin: "https://www.linkedin.com/in/rossmeredith/",
        author_paypal_email: "arty.hermes@gmail.com",
        author_shop_website: "https://www.behance.net/artyhermes",
        author_website: "https://rossmeredith.myportfolio.com/",
        categories: [""],
        description: "Tacked – With nods to the Deconstructionism movement of the 1980s, Tacked lacks harmony, continuity, or symmetry. Tacked is more about the shapes that the letterforms make together than it is about legibility. Ross Meredith released the typeface in July 2019 under the supervision and advice of the legendary, Bonita Tanaka of Santa Monica College.",
        font_download: "tacked.zip",
        font_family: "tacked",
        font_notes: "",
        font_supported_case: "",
        poster_alternates: [""],
        poster_large: "tacked_large.png",
        poster_small: "tacked_small.png",
        poster_link: "",
        size_small: "10px",
        size_medium: "14px",
        size_large: "32px",
        size_xlarge: "46px",
        styles: [],
        tanaka_rank: 13,
    },
    { 
        font_name: "Doughnutello",
        author_name_full: "Kseniia Korniienko",
        author_name_last: "Korniienko",
        author_bio_image: "doughnutello.jpg",
        author_bio_text: "Born in Ukraine, doing art from childhood. Graduated from Santa Monica College. Want to create clothing line with type designs.",
        author_linkedin: "https://www.linkedin.com/in/kseniiakorniienko/",
        author_paypal_email: "aartksikor@gmail.com",
        author_shop_website: "https://www.behance.net/xenako",
        author_website: "https://www.behance.net/xenako",
        categories: [""],
        description: "Doughnutello is a display font which is used in cheerful designs like birthday flyers, anniversary invitations, in any kind of celebration materials. This typeface is made of a round shape like a doughnut and a drip of chocolate.",
        font_download: "doughnutello.zip",
        font_family: "doughnutello",
        font_notes: "",
        font_supported_case: "upper",
        poster_alternates: [""],
        poster_large: "poster_new.png",
        poster_small: "poster_new.png",
        poster_link: "",
        size_small: "10px",
        size_medium: "14px",
        size_large: "32px",
        size_xlarge: "46px",
        styles: [],
        tanaka_rank: 14,
    },
    { 
        font_name: "Psychic Dreamer",
        author_name_full: "Randi Drozd",
        author_name_last: "Drozd",
        author_bio_image: "headcoinlogo.png",
        author_bio_text: "Randi Drozd is a Los Angeles based illustrator, graphic designer, and painter originally from Chicago. She is currently a student at California State University Long Beach.",
        author_linkedin: "",
        author_paypal_email: "thewolfram@gmail.com",
        author_shop_website: "https://randidrozd.bigcartel.com/",
        author_website: "http://www.randidrozd.com/",
        categories: [""],
        description: "Psychic Dreamer is a display font which has a psychedelic feel. The concept created has a rhythmic quality; the letters sway and often loop down within a wide arch. Some of the inspirations behind Psychic Dreamer include the waves of an ocean and an eyelet of a peacock feather! Specified usage for any platform, which encapsulates an illusory state of imagination and dream like sensation while blurring the realms of reality with mind bending deliberation? Yet, Psychic Dreamer can be used for music, magazines, skateboarding, cannabis products, alcohol, and fragrances.",
        font_download: "psychicdreamer.zip",
        font_family: "psychicdreamer",
        font_notes: "",
        font_supported_case: "upper",
        poster_alternates: [""],
        poster_large: "psychicdreamerNEW.png",
        poster_small: "psychicdreamerNEW.png",
        poster_link: "",
        size_small: "10px",
        size_medium: "14px",
        size_large: "24px",
        size_xlarge: "46px",
        styles: [],
        tanaka_rank: 15,
    },
];

// TODO: make a fontOrder array
/*
export const fontOrder = [
    'Horizon West',
    'Contranect',
    // etc
];
*/
// TODO: when getting font data, use tanakaRank from fontOrder rather than const fonts

/**
 * License options
 */
export const personalMinimum = 7.5;
export const licenseOptions = [
    {
        value: 'personal',
        dropdown_text: 'Personal Use - starting at $7.50',
        description: 'Personal Use',
        amount: personalMinimum,
        zip: 'personal'
    },
    {
        value: 'commercial_1_5',
        dropdown_text: 'Commercial Use - 1-5 users',
        description: 'Commercial Use for 1-5 users',
        amount: 75,
        zip: 'commercial'
    },
    {
        value: 'commercial_6_20',
        dropdown_text: 'Commercial Use - 6-20 users',
        description: 'Commercial Use for 6-20 users',
        amount: 100,
        zip: 'commercial'
    },
    {
        value: 'commercial_21',
        dropdown_text: 'Commercial Use - 21+ users',
        description: 'Commercial Use for over 21 users',
        amount: 200,
        zip: 'commercial'
    },
];

const licenseLookupMap = {};
licenseOptions.forEach(license => {
    licenseLookupMap[license.value] = { 
        amount: license.amount,
        dropdown_text: license.dropdown_text,
        description: license.description,
        zip: license.zip
    };
});
export const licenseLookup = licenseLookupMap;