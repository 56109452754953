import React, { useEffect, useState } from "react";
import "./CyclingImage.scss";

const TRANSITION_MS = 1000;
const STATIC_MS = 4000;

const getNextImageIndex = (currentIndex, maxLength) => {
    const next = currentIndex + 1;
    return next < maxLength ? next : 0;
};

const CyclingImage = ({ imageFileNames, initialDelay = 0 }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const updateImageIndex = current => getNextImageIndex(current, imageFileNames.length);

    const startNextImage = () => {
        setTimeout(() => {
            setCurrentImageIndex(updateImageIndex);
        }, TRANSITION_MS);
    };

    useEffect(() => {
        setTimeout(() => {
            setInterval(() => {
                startNextImage();
            }, STATIC_MS);
        }, initialDelay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const images = imageFileNames.map((fileName, index) => {
        const imageStyles = {
            backgroundImage: `url(../carousel/${fileName})`,
            opacity: index === currentImageIndex ? 1 : 0,
        };
        return <div key={fileName} className="cycling-image" style={imageStyles}></div>
    });
    
    return (
        <div className="cycling-image-container">
            { images }
        </div>
    );
}

export default CyclingImage;