import React from "react";
import { MDBIcon } from "mdbreact";
import './BasicDropdownToggle.scss';

export class BasicDropdownToggle extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        e.preventDefault();
        this.props.onClick(e);
    }

    render() {
        const icon = this.props['aria-expanded']
            ? <MDBIcon icon="times" className="menuIcon closeIcon"/>
            : <MDBIcon icon="bars" className="menuIcon"/>;
        return (
            <div className="basicDropdownToggle" onClick={this.handleClick}>
                {icon} {this.props.children}
            </div>
        );
    }
}