import React from "react";
import PropTypes from "prop-types";

import AuthorLinks from "components/AuthorLinks";

import "./Author.scss";

const propTypes = {
  font: PropTypes.object.isRequired,
  showLinks: PropTypes.bool,
};

const defaultProps = {
  showLinks: false,
};

const Author = ({ font, showLinks, ...props }) => {
  const authorName = font.author_name_full;
  const authorBackgroundImg = `url(../bios/${font.author_bio_image})`;
  const classNameFromParent = props.className ? `${props.className} ` : '';
  
  return (
    <div className={`${classNameFromParent}author`}>
      <div className="authorImage" style={{ backgroundImage: authorBackgroundImg }}></div>
      <div className="authorData">
        <div className="authorName">{authorName}</div>
        {showLinks && (
          <AuthorLinks font={font} />
        )}
      </div>
    </div>
  );
};

Author.propTypes = propTypes;
Author.defaultProps = defaultProps;

export default Author;