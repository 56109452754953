import React from "react";
import { withRouter } from "react-router-dom";
import { licenseLookup, licenseOptions, personalMinimum } from "../data";

import "./BuyFont.scss"

// TODO: refactor as function component

class BuyFontClass extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            licenseType: 'personal',
            personalAmount: licenseOptions[0].amount
        };
        this.toSelectOption = this.toSelectOption.bind(this);
        this.handleLicenseChange = this.handleLicenseChange.bind(this);
        this.handleAmountChange = this.handleAmountChange.bind(this);
        this.handleAmountBlur = this.handleAmountBlur.bind(this);
        this.updateAmount = this.updateAmount.bind(this);
        this.getAmount = this.getAmount.bind(this);
        this.getAmountNumber = this.getAmountNumber.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);
    }

    toSelectOption(licenseOption) {
        return (<option key={licenseOption.value} value={licenseOption.value}>
                {licenseOption.dropdown_text}
            </option>);
    }

    // TODO: can probably get rid of this
    getPlaceholder(licenseType) {
        return licenseType === 'personal'
            ? '10, 20, 50, etc.'
            : '';
    }

    getAmount(licenseType) {
        return `$${this.getAmountNumber(licenseType)}`;
    }

    getAmountNumber(licenseType) {
        if (licenseType === 'personal') return this.state.personalAmount;
        return licenseLookup[licenseType].amount;
    }

    handleLicenseChange(event) {
        this.setState({ licenseType: event.target.value });
    }

    handleAmountChange(event) {
        this.updateAmount(event);
    }

    handleAmountBlur(event) {
        this.updateAmount(event, true);
    }
    
    updateAmount(event, adjustInput = false) {
        if (this.state.licenseType !== 'personal') return;
        const cleanAmount = event.target.value.replace(/\$|,/g, '');
        let personalAmount = cleanAmount;
        if (adjustInput) {
            personalAmount = parseInt(personalAmount);
            if (isNaN(personalAmount)) {
                personalAmount = personalMinimum;
            }
            if (personalAmount < personalMinimum) {
                personalAmount = personalMinimum;
            }
        }
        this.setState({ personalAmount });
    }

    getIsButtonDisabled(amount) {
        return amount < personalMinimum;
    }

    handleButtonClick() {
        const { font, history } = this.props;
        const { licenseType } = this.state;
        const amount = this.getAmountNumber(licenseType);
        const fontPaymentPage = `/payment/${font.font_name}?license=${licenseType}&amount=${amount}`;
        history.push(fontPaymentPage);
    }

    render() {
        const { licenseType, personalAmount} = this.state;
        const { font } = this.props;
        const { font_name: fontName } = font;
        const options = licenseOptions.map(this.toSelectOption);
        const placeholder = this.getPlaceholder(licenseType);
        const amount = this.getAmount(licenseType);
        const isbuttonDisabled = this.getIsButtonDisabled(personalAmount);

        const classNameFromParent = this.props.className ? `${this.props.className} ` : '';
        
        return (
            <div className={`${classNameFromParent}buyFont form-group`}>
                <h4 className="licensingInfoHeading">
                    Download Font Now!
                </h4>
                <select className="form-control" onChange={this.handleLicenseChange} value={licenseType}>
                    {options}
                </select>
                <input 
                    className="form-control amountInput" 
                    type="text" 
                    placeholder={placeholder} 
                    onChange={this.handleAmountChange} 
                    onBlur={this.handleAmountBlur} 
                    value={amount}
                />
                <button 
                    type="button" 
                    className="downloadButton"
                    disabled={isbuttonDisabled}
                    onClick={this.handleButtonClick}
                >
                    Download {fontName}
                </button>
            </div>
        );
    }
};

export const BuyFont = withRouter(BuyFontClass);