import React from "react";
import { Link } from "react-router-dom";
import { NavMenu } from "./NavMenu";

import "./Header.scss"

// TODO: logo tiles will be 44px x 44px, images will be 88 x 88
// logo will be 132 x 44 (264 x 88 img)

export class Header extends React.Component {
    render() {
        const { hasNav } = this.props;
        const navMenu = hasNav ? <NavMenu /> : null;

        return (
            <div className="headerSizing">
                <div className="header">
                    <div className="headerContent">
                        <div className="logo">
                            <Link to="/">
                                <div className="letters">
                                    <span className="letters_01 letters_a font_le_cirq letter">TA</span>
                                    <span className="letters_01 letters_b font_bevelton letter">NA</span>
                                    <span className="letters_01 letters_c font_meringue_puff letter">KA</span>
                                    <span className="letters_02 letters_a font_contranect letter">ta</span>
                                    <span className="letters_02 letters_b font_horizon_west letter">NA</span>
                                    <span className="letters_02 letters_c font_antoni letter">ka</span>
                                    <span className="letters_03 letters_a font_lurch letter">TA</span>
                                    <span className="letters_03 letters_b font_kings_and_queens letter">NA</span>
                                    <span className="letters_03 letters_c font_blockbot letter">ka</span>
                                </div> 
                                <span className="typeClub">
                                    typeclub
                                </span>
                            </Link>
                        </div>
                        { hasNav &&
                            <nav className="desktopNav">
                                <Link className="desktopNavLink" to="/">Home</Link>
                                <Link className="desktopNavLink" to="/fonts">Fonts</Link>
                                <Link className="desktopNavLink" to="/contributors">Contributors</Link>
                                <Link className="desktopNavLink" to="/about">About</Link>
                                <Link className="desktopNavLink" to="/faq">FAQ</Link>
                                <Link className="desktopNavButton" to="/donate">Donate</Link>
                            </nav>
                        }
                        {navMenu}
                    </div>
                </div>
            </div>
        );
    }
};