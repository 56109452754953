import React from "react";
import { Link } from "react-router-dom";
import { FontTestDrive } from "components/FontTestDrive";

import './FontList.scss';

import { byRank, getTextTransform } from "dataUtils";
import { fonts } from "data";

export class FontList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sampleText: window.testDriveText || "Type Here..."
        };
        this.testDrive = this.testDrive.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
    }

    testDrive(font) {
        const fontFamily = `${font.font_family}, Helvetica`;
        const fontSize = `${font.size_large}`;
        const textTransform = getTextTransform(font.font_supported_case);
            
        return <div className="testDrive">
            <input 
                type="text" 
                className="testDriveInput"
                style={{fontFamily, fontSize, textTransform}} 
                onChange={this.handleTextChange}
                value={this.state.sampleText}
            />
        </div>;
    }

    handleTextChange(event) {
        this.setState({ sampleText: event.target.value });
    }
    
    list() {
        return fonts.sort(byRank).map((font) => {
            const fontFamily = `${font.font_family}, Helvetica`;
            const fontSize = `${font.size_large}`;
            const fontDetailPage = `/font/${font.font_name}`;
            const textTransform = `${getTextTransform(font.font_supported_case)}`;
            return (
                <tr key={font.font_name}>
                    <td style={{fontFamily, fontSize, textTransform}} className="fontNameColumn">
                        <Link className="fontName" to={fontDetailPage}>{font.font_name}</Link>
                    </td>
                    <td style={{fontFamily}}>
                        <FontTestDrive 
                            font={font} 
                            textContent={this.state.sampleText}
                            config={{
                                basic: true, 
                                onTextChange: this.handleTextChange
                            }}
                        />
                    </td>
                </tr>
            );
        });
    }

    listMobile() {
        return fonts.map((font) => {
            const fontFamily = `${font.font_family}, Helvetica`;
            const fontSize = `${font.size_large}`;
            const fontDetailPage = `/font/${font.font_name}`;
            const testDrive = this.testDrive(font);
            const textTransform = `${getTextTransform(font.font_supported_case)}`;
            return (
                <div key={font.font_name} className="fontItem">
                    <div style={{fontFamily, fontSize, textTransform}} className="fontNameColumn">
                        <Link className="fontName" to={fontDetailPage}>{font.font_name}</Link>
                    </div>
                    <div style={{fontFamily}}>{testDrive}</div>
                </div>
            );
        });
    }
    
    render() {
        return (
            <div className="fontList">
                <table className="fontListTable">
                    <tbody>
                        {this.list()}
                    </tbody>
                </table>
                <div className="fontsListMobile">
                    {this.listMobile()}
                </div>
            </div>
        );
    }
};