import React, { useState } from "react";
import { Link } from "react-router-dom";
import NavPage from "components/NavPage";
import CyclingImage from "components/CyclingImage";
import { Helmet } from 'react-helmet';
import { redirectToDonation } from 'util/paypalUtils';
import { carouselImageSegments } from '../dataUtils';

import "./About.scss";

export function About() {
    const [donationAmount, setDonationAmount] = useState();
    const cyclingImageCollections = carouselImageSegments(3);
    return (
        <NavPage headerGradientOnly={true}>
            <Helmet>
                <title>TA NA KA Typeclub: About</title>
                <meta name="description" content="Tanaka Typeclub: about our mission as an independent not-for-profit type foundry, and the people who make this possible." />
            </Helmet>
            <div className="aboutHero">
                <CyclingImage className="imgCycle1" imageFileNames={cyclingImageCollections[1]} initialDelay={1000} />
                <CyclingImage className="imgCycle2" imageFileNames={cyclingImageCollections[0]} />
                <CyclingImage className="imgCycle3" imageFileNames={cyclingImageCollections[2]} initialDelay={2000} />
                <div className="aboutHeading"><h1>About<br />TANAKA<br />Typeclub</h1></div>
                <div className="aboutHeadingMobile"><h1>About TANAKA Typeclub</h1></div>
                <div className="aboutDescription">
                    <p>
                        This site is a curated collection of student designed fonts from a variety of
                        colleges in the Los Angeles area. Designing a typeface under the category of
                        display or decorative is a way for student designers to take a deeper dive
                        into the understanding of typography.
                    </p>
                    <p>
                        Some of the fonts are all caps while some are upper and lower case while a
                        few are unicase. The OTF files are available for purchase and download at a
                        fair market price of $7.50 for the personal use license and additional options
                        for professional usage.
                        (Please see the documentation in the <Link to="/faq">Frequently Asked Questions</Link> section).
                    </p>
                </div>
                <div className="aboutDonate">
                    <h2>Help Support TANAKA Typeclub.</h2>
                    <p>
                        Tanaka Typeclub is a not-for-profit site and 100% of the type proceeds go directly
                        to the designers. Donations help us keep the site running.
                    </p>
                    <div className="donateBox">
                        <h5>Donate Now!</h5>
                        <input
                            type="text"
                            className="donateInput"
                            placeholder="$5, $10, $15, $20..."
                            onChange={(e) => setDonationAmount(e.target.value)}
                        ></input>
                        <button 
                            type="button" 
                            className="donateButton"
                            onClick={() => redirectToDonation(donationAmount)}
                        >
                            Donate
                        </button>
                    </div>
                </div>
            </div>
            <div className="aboutDivider"></div>
            <div className="aboutPeople">
                <div className="bonitaElsaImg" style={{backgroundImage : `url('../bios/about_bonita_elsa.png')`}}></div>
                <div className="bonitaElsaText">
                    <h5>Bonita &amp; Elsa</h5>
                    <p>
                        Bonita Tanaka and Elsa Grayson met in the classroom.
                        Their relationship began as mentor-student, later
                        evolving to mentor-internship and colleague which
                        resulted in the collaboration of the TANAKA TYPE CLUB.
                    </p>
                </div>
                <div className="profileImg profileImg1">
                    <div className="circleImg" style={{backgroundImage: `url('../bios/about_bonita_profile.png')`}}></div>
                </div>
                <div className="profileText profileText1">
                    <h2>Bonita Tanaka</h2>
                    <p>
                        <a href="http://www.bonnietanaka.com">Portfolio</a>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <a href="https://www.instagram.com/tanakastudents_type/?hl=en">Instagram</a>
                    </p>
                    <p>
                        Bonita Tanaka’s hybrid practice encompasses multiple roles- Graphic
                        Designer / Artist / Educator / Art Director / Mentor / Writer / and Researcher.
                        Her expertise in the field of graphic design has led her to work in print / web /
                        installation and education, where she utilizes her multiple disciplines.
                    </p>
                </div>
                <div className="profileImg profileImg2">
                    <div className="circleImg" style={{backgroundImage: `url('../bios/about_elsa_profile.png')`}}></div>
                </div>
                <div className="profileText profileText2">
                    <h2>Elsa Grayson</h2>
                    <p>
                        <a href="http://elsagraysondesign.com">Portfolio</a>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <a href="https://www.linkedin.com/in/elsa-grayson/">LinkedIn</a>
                    </p>
                    <p>
                        Elsa is an interaction designer.
                        Her projects explore human-centered design through research, branding, 
                        print, mobile apps, and the web. In addition, she is a proponent of
                        guerrilla gardening, and is quietly obsessed with all things illustration
                        and type.
                    </p>
                </div>
                <div className="creditsImg" style={{backgroundImage : `url('../bios/about_bonita_dan.png')`}}></div>
                <div className="creditsText">
                    <h2>Credits</h2>
                    <p>
                        Bonita Tanaka: Art direction and design <br />
                        Elsa Grayson: Web / Graphic / UX design, product management <br />
                        Dan Grayson: Software Engineering <br />
                        <br />
                        And a special thanks to all the cats and dogs who supported this project:
                        White Kitty, Bear, Cole, Egon, and Gustav.
                    </p>
                </div>
                
            </div>
        </NavPage>
    );
}