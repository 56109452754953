import React from "react";
import { Helmet } from 'react-helmet';
import FontTiles from "components/FontTiles";
import NavPage from "components/NavPage";

export function Home() {
    return (
        <NavPage>
            <Helmet>
                <title>TA NA KA Typeclub</title>
                <meta name="description" content="Tanaka Typeclub is a not-for-profit type foundry that features hand-curated fonts by independent creators." />
            </Helmet>
            <FontTiles />
        </NavPage>
    );
}