import React from "react";
import './DropdownItem.scss';

export class DownloadZip extends React.Component {
    componentDidMount() {
        const { font, license } = this.props;
        const fontName = this.formatFontName(font.font_name);
        const zipFile = `${fontName}_${license}.zip`;
        var zip_file_path = `../downloads/${zipFile}`;
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = zip_file_path;
        a.download = zipFile;
        console.log("downloading", zip_file_path);
        a.click();
        document.body.removeChild(a);
    }

    formatFontName(fontName) {
        return fontName.toLowerCase().replace(/ /g, '_');
    }
    
    render() {
        return (
            <div style={{ display: "none" }}>
                (font downloader present)
            </div>
        );
    }
}