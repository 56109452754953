import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { About, Contributors, Donate, Fonts, FontDetail, FontDownload, FontPayment, FrequentlyAskedQuestions, Home, Thanks, DonationThanks } from "views/index";
import './App.css';

function TanakaTypeClub() {
  return (
    <Router>
      <div>
        <Route exact path="/" component={Home} />
        <Route exact path="/fonts" component={Fonts} />
        <Route exact path="/contributors" component={Contributors} />
        <Route exact path="/about" component={About} />
        <Route exact path="/donate" component={Donate} />
        <Route exact path="/faq" component={FrequentlyAskedQuestions} />
        <Route exact path="/font_thank_you" component={Thanks} />
        <Route exact path="/tanaka_thank_you" component={DonationThanks} />
        <Route path="/font/:font_name" component={FontDetail} />
        <Route path="/payment/:font_name" component={FontPayment} />
        <Route path="/download/:font_name" component={FontDownload} />
      </div>
    </Router>
  );
}

export default TanakaTypeClub;
