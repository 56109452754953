import React from "react";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'; 

import './Footer.scss';

library.add(faFacebook); 
library.add(faInstagram); 

export class Footer extends React.Component {
    render() {
        return (
            <div className="footer">
                <div className="socialIcons">
                    <span>
                        <a href="https://www.instagram.com/tanakatypeclub/?hl=en">
                            <FontAwesomeIcon icon={['fab', 'instagram']}/>
                        </a>
                    </span>
                    {/* <span><FontAwesomeIcon icon={['fab', 'facebook']}/></span> */}
                </div>
                <div className="disclaimer">
                    TA NA KA Type Club / &copy; 2019 Bonita Tanaka, All rights reserved / contact: &nbsp;
                    <a href="mailto:admin@tanakatypeclub.com">admin@tanakatypeclub.com</a>
                </div>
            </div>
        );
    }
};