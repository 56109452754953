import React from "react";
import { Link } from "react-router-dom";

import Author from "components/Author";

import { fonts } from "data";
import { byRank, getTextTransform } from "dataUtils";

import './FontTiles.scss';

const FontTiles = () => {
    const tiles = fonts.sort(byRank).map((font) => {
        const fontFamily = `${font.font_family}, Helvetica`;
        const fontSize = font.size_large;
        const fontDetailPage = `/font/${font.font_name}`;
        const posterImgBackground = `url(../posters/${font.poster_small})`;
        const textTransform = `${getTextTransform(font.font_supported_case)}`;
        const top = font.offset_top_large || '0';

        return (
            <Link to={fontDetailPage} key={font.font_name} className="fontTile">
                <div className="fontPosterImgContainer" style={{ backgroundImage: posterImgBackground }}></div>
                <div className="fontInfo">
                    <div className="fontName" style={{ fontFamily, fontSize, textTransform, top }}>
                        {font.font_name}
                    </div>
                    <Author font={font} />
                </div>
            </Link>
        );
    });

    return (
        <div className="fontTilesContainer">
            <div className="fontTilesGrid">
                {tiles}
            </div>
        </div>
    );
};

export default FontTiles;