import React, { useState } from "react";
import { licenseLookup } from "../data";
import { createPaypalLink } from "../util/paypalUtils";

import './FontDownloadTile.scss';

/**
 * If set to true, this will disable download/payment functionality.
 * Use this when there are technical issues that need to be fixed.
 */
const DISABLE_DOWNLOADS = false;
// const SECONDS_UNTIL_PAYPAL = 6;

const getReturnUrl = (font, license) => {
    return `${window.location.origin}/download/${font.font_name}?license=${license}`;
}

const getPaypalLink = (customAmount, font, license) => {
    const { author_paypal_email: paypalEmail, font_name: itemName} = font;
    const amount = licenseLookup[license].amount || customAmount;
    const returnUrl = getReturnUrl(font, license);
    return createPaypalLink(paypalEmail, itemName, amount, returnUrl);
};

const redirectToPaypal = (amount, font, license) => {
    const paypal = getPaypalLink(amount, font, license);
    if (font.author_paypal_email && !DISABLE_DOWNLOADS) {
        window.location.href = paypal;
    }
    if (DISABLE_DOWNLOADS) {
        console.log('redirect to', paypal);
    }
};

const handleButtonClick = (amount, font, license) => {
    redirectToPaypal(amount, font, license);
};

export function FontPaymentTile({ amount, font, license }) {
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const { description: licenseDescription } = licenseLookup[license];
    const { font_name: fontName } = font;

    if (DISABLE_DOWNLOADS) {
        return (
            <div className="fontDownloadTile">
                <h2>Payment for {fontName}</h2>
                <div>
                    Sorry, but your
                    <span className="licenseDescription"> {licenseDescription} </span>
                    package is not available for download right now. Please check back soon and we hope to have everything working!
                </div>
            </div>
        );
    }

    return (
        <div className="fontDownloadTile">
            <h2>Payment for {fontName}</h2>
            <p>
                To pay for your {licenseDescription} package for {fontName}, you'll be sent to a PayPal donation page for you to pay the designer directly.     
            </p>
            <p>
                After your payment is complete, <strong>DO NOT CLOSE THE BROWSER OR NAVIGATE AWAY!</strong>
            </p>
            <p>    
                PayPal will <strong>automatically</strong> redirect you to download your font and license files.
            </p>
            <p>
                <input type="checkbox" onChange={(e) => setIsButtonDisabled(!e.target.checked)}></input> I understand.
            </p>
            <button 
                    type="button" 
                    className="btn btn-info downloadButton"
                    disabled={isButtonDisabled}
                    onClick={() => handleButtonClick(amount, font, license)}
                >
                    Get {fontName}
                </button>
        </div>
    );
}
