// Helpers for the sorting functions

const byProperty = (a, b, propertyName, type) => {
    const aProp = formatProperty(a[propertyName], type);
    const bProp = formatProperty(b[propertyName], type);
    if(aProp < bProp) { return -1; }
    if(aProp > bProp) { return 1; }
    return 0;
};

const formatProperty = (val, type) => {
    const value = val === undefined ? "" : val;
    if (type === "string") return value.toLowerCase();
    if (type === "number") return parseInt(value);
    return value;
};

// Sorting functions for the data set

export const byLastName = (a, b) => {
    return byProperty(a, b, "author_name_last", "string");
};

export const byFontName = (a, b) => {
    return byProperty(a, b, "font_name", "string");
};

export const byRank = (a, b) => {
    return byProperty(a, b, "tanaka_rank", "number");
};

// Getting alternate views of the data set

export const getAuthors = (fonts) => {
    const seenAuthors = [];
    return fonts.filter(font => {
        if (seenAuthors.includes(font.author_name_full)) {
            return false;
        }
        seenAuthors.push(font.author_name_full);
        return true;
    }).map(author => {
        const authorFonts = fonts.filter(possibleAuthorFont => {
            return possibleAuthorFont.author_name_full === author.author_name_full;
        });
        author.all_fonts = authorFonts;
        return author;
    });
};

export const getRandomImages = (images, howMany) => {
    const lastIndex = images.length - 1;
    const randomImages = [];
    while (randomImages.length < howMany) {
        const randomIndex = Math.min(Math.floor(Math.random() * images.length), lastIndex);
        if (!randomImages.includes(images[randomIndex])) {
            randomImages.push(images[randomIndex]);
        }
    }
    return randomImages;
};

export const getTextTransform = (supportedCase) => {
    switch(supportedCase) {
        case "lower":
            return "lowercase";
        case "all":
            return "none";
        case "upper":
            return "uppercase";
        default:
            return "none";
    }
};

export const carouselImages = [
    'IMG_4133.jpeg',
    'IMG_2014-05-13_1400001679.jpg',
    'IMG_2014-05-13_1399997904.jpg',
    'IMG_0968.jpg',
    'IMG_1080.jpg',
    'IMG_8809.jpeg',
    'IMG_1255.jpg',
    'IMG_8265.jpg',
    'IMG_2014-05-13_1400000571.jpg',
    'IMG_0748.jpg',
    'IMG_4704.jpg',
    'IMG_8260.jpg',
    'IMG_0612.jpg',
    'IMG_1795.jpeg',
    'IMG_4702.jpeg',
    'IMG_1916.jpeg',
    'IMG_4920.jpg',
    'IMG_1079.jpg',
    'IMG_1078.jpg',
    'IMG_8666.jpg',
    'IMG_1760.jpeg',
    'IMG_2014-01-16_1389902144.jpg',
    'IMG_1792.jpeg',
    'IMG_5745.jpeg',
    'IMG_8238.jpg',
    'IMG_8172.jpg',
    'IMG_1897.jpg',
    'IMG_5092.jpeg',
    'IMG_1710.jpeg',
    'IMG_0988.jpeg',
    'IMG_8842.jpeg',
    'IMG_8164.jpg',
    'IMG_4924.jpeg',
    'IMG_4066.jpeg',
    'IMG_8213.jpg',
    'IMG_5749.jpeg',
    'IMG_0459.jpeg',
    'IMG_1248.jpeg',
    'IMG_2892.jpg',
    'IMG_4509.jpeg',
    'IMG_8217.jpg',
    'IMG_8216.jpg',
    'IMG_8160.jpg',
    'IMG_2014-05-13_1399999594.jpg',
    'IMG_2014-05-13_1400000844.jpg',
    'IMG_1750.jpeg',
    'IMG_8200.jpg',
    'IMG_2013-04-30_1367352841.jpg',
    'IMG_5142.jpeg',
    'IMG_0379.jpg',
    'IMG_0888.jpg',
    'IMG_9112.jpg',
    'IMG_6803.jpg',
    'IMG_1856.jpeg',
    'IMG_5846.jpg',
    'IMG_3373.jpeg',
    'IMG_1749.jpeg',
    'IMG_6222.jpg',
    'IMG_2014-08-08_1407541485.jpg',
    'IMG_0083.jpg',
    'IMG_7703.jpg',
    'IMG_4222.jpg',
    'IMG_7665.jpeg',
    'IMG_2014-01-16_1389902002.jpg',
    'IMG_1898.jpeg',
    'IMG_4545.jpg',
    'IMG_7604.jpeg',
    'IMG_1946.jpg',
    'IMG_0331.jpg',
    'IMG_5746.jpeg',
    'IMG_6219.jpg',
    'IMG_8220.jpg',
    'IMG_7623.jpg',
    'IMG_0365.jpeg',
    'IMG_2159.jpeg',
    'IMG_4859.jpg',
    'IMG_0949.jpg',
    'IMG_1710.jpg',
    'IMG_6042.jpg',
    'IMG_4906.jpg',
    'IMG_5281.jpeg',
    'IMG_4701.jpeg',
    'IMG_6867.jpg',
    'IMG_8255.jpg',
    'IMG_3155.jpeg',
    'IMG_6087.jpeg',
    'IMG_1742.jpeg',
    'IMG_6872.jpg',
    'IMG_5835.jpg',
    'IMG_F8652.jpg',
    'IMG_7603.jpg',
    'IMG_6871.jpg'
];

export const carouselImageSegments = (numSegments) => {
    const base = carouselImages;
    const segmentSize = Math.floor(base.length / numSegments);
    const remainder = base.length % numSegments;
    const segments = [];
    for (let i = 0; i < numSegments; i++ ) {
        const firstIndex = i * segmentSize;
        const lastIndex = i * segmentSize + segmentSize;
        segments[i] = base.slice(firstIndex, lastIndex);
    }
    if (remainder > 0) {
        const last = segments.length - 1;
        const remainderFirstIndex = base.length - remainder;
        const remainderElements = base.slice(remainderFirstIndex, base.length);
        segments[last] = segments[last].concat(remainderElements); 
    }
    return segments;
};