import React from "react";
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import { NonNavPage } from "components/NonNavPage";
import { FontPaymentTile } from "components/FontPaymentTile";

import { fonts } from "data";

export function FontPayment({ match, location }) {
    const font = fonts.find((entry) => {
        return entry.font_name === match.params.font_name;
    });
    const values = queryString.parse(location.search);
    const { amount, license } = values;

    return (
        <NonNavPage>
            <Helmet>
                <title>TA NA KA Typeclub: Payment {font.font_name}</title>
                <meta name="description" content="Tanaka Typeclub payment page. You will be redirected to PayPal shortly." />
            </Helmet>
            <FontPaymentTile amount={amount} font={font} license={license} />
        </NonNavPage>
    );
}