import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  font: PropTypes.object.isRequired,
  showContact: PropTypes.bool,
};

const defaultProps = {
  showContact: false,
};

const AuthorLinks = ({ font, showContact }) => {
  const { 
    author_linkedin: linkedIn, 
    author_shop_website: shop, 
    author_website: website 
  } = font;
  if (!(linkedIn || shop || website)) {
    return null;
  }
  return (
    <div className="authorLinks">
      {showContact && (
        <React.Fragment>
          Contact:&nbsp; 
        </React.Fragment>
      )}
      {linkedIn && (
        <React.Fragment>
          <a href={linkedIn} className="authorLink">LinkedIn</a>&nbsp;&nbsp;
        </React.Fragment>
      )}
      {shop && (
        <React.Fragment>
          <a href={shop} className="authorLink">Shop</a>&nbsp;&nbsp;
        </React.Fragment>
      )}
      {website && (
        <React.Fragment>
          <a href={website} className="authorLink">Website</a>&nbsp;&nbsp;
        </React.Fragment>
      )}
    </div>
  );
};

AuthorLinks.propTypes = propTypes;
AuthorLinks.defaultProps = defaultProps;

export default AuthorLinks;