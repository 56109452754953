import React from "react";
import { Helmet } from 'react-helmet';
import NavPage from "components/NavPage";
import { redirectToDonation } from 'util/paypalUtils';

import './Thanks.scss';

export function Thanks() {
    return (
        <NavPage>
            <Helmet>
                <title>TA NA KA Typeclub: Thanks!</title>
                <meta name="description" content="Tanaka Typeclub thanks you for supporting independent type creators!" />
            </Helmet>
            <div className="thanksTile">
                <h2>Thanks!</h2>
                <p>
                    Thank you for supporting independent type creators. You can feel good knowing that
                    100% of your contribution went directly to the designer - Tanaka Type Club takes no cut.

                    At Tanaka Type Club, we rely on donations from people like you to keep this operation running.

                    Can you spare an extra dollar or two to support our mission of providing great independent 
                    typefaces?
                    <br />
                    <br />

                    <button 
                        type="button" 
                        className="btn btn-info tanakaDonateButton"
                        onClick={() => redirectToDonation()}
                    >
                        Donate to support Tanaka Type Club
                    </button>
                </p>
            </div>
        </NavPage>
    );
}