import React from "react";
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import { NonNavPage } from "components/NonNavPage";
import { FontDownloadTile } from "components/FontDownloadTile";

import { fonts } from "data";

export function FontDownload({ match, location }) {
    const font = fonts.find((entry) => {
        return entry.font_name === match.params.font_name;
    });
    const values = queryString.parse(location.search);
    const { license } = values;

    return (
        <NonNavPage>
            <Helmet>
                <title>TA NA KA Typeclub: Downloading {font.font_name}</title>
                <meta name="description" content="Tanaka Typeclub: Downloading your font." />
            </Helmet>
            <FontDownloadTile font={font} license={license} />
        </NonNavPage>
    );
}