import React from "react";
import NavPage from "components/NavPage";
import { Helmet } from 'react-helmet';
import { redirectToDonation } from 'util/paypalUtils';

import "./Donate.scss";

export function Donate() {
    return (
        <NavPage headerGradientOnly={true}>
            <Helmet>
                <title>TA NA KA Typeclub: Donate</title>
                <meta name="description" content="Tanaka Typeclub: donate to our independent not-for-profit type foundry." />
            </Helmet>
            <div className="aboutPage">
                <div className="flexRow mobileFlexColumn padTop sideMargins">
                    <div className="textColumn">
                        <h2>Donate to Tanaka Typeclub</h2>
                        <p>
                            Tanaka Typeclub is a not-for-profit site and 100% of the type proceeds go directly
                            to the designers. Donations help us keep the site running.
                        </p>
                        <div className="donateButtonsContainer">
                            <button className="donateButton" onClick={() => redirectToDonation(1)}>Donate $1</button>
                            <button className="donateButton" onClick={() => redirectToDonation(5)}>Donate $5</button>
                            <button className="donateButton" onClick={() => redirectToDonation(10)}>Donate $10</button>
                            <button className="donateButton" onClick={() => redirectToDonation(20)}>Donate $20</button>
                            <button className="donateButton" onClick={() => redirectToDonation(35)}>Donate $35</button>
                            <button className="donateButton" onClick={() => redirectToDonation(50)}>Donate $50</button>
                        </div>
                    </div>
                </div>
            </div>
        </NavPage>
    );
}