import React from "react";

import "./FontDescription.scss";

export function FontDescription({ font, ...props }) {

    const classNameFromParent = props.className ? `${props.className} ` : '';

    const { categories : fontCategories = [] } = font;
    const hasCategories = !!fontCategories.filter(a => a).length;
    const categories = hasCategories ? fontCategories.map((category, index) => {
        const separatingComma = index < fontCategories.length - 1 ? ', ' : '';
        return <span key={category}>{category}{separatingComma}</span>
    }) : 'Display Font';
    console.log({categories});

    return (
        <div className={`${classNameFromParent}fontDescription`}>
            <h5>{font.font_name}</h5>
            <h6 className="categories">{categories}</h6>
            <p>
                {font.description}
            </p>
        </div>
    );
}