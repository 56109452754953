import React from "react";

import './FontPoster.scss';

export function FontPoster({ font, ...props }) {    
    const posterImg = `../posters/${font.poster_small}`;
    const classNameFromParent = props.className ? `${props.className} ` : '';

    return (
        <div key={font.font_name} className={`${classNameFromParent}fontPoster`}>
            <img alt={font.font_name} className="fontPosterImg" src={posterImg} />
        </div>
    );
};