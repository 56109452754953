import React, { useEffect } from "react";
import { Helmet } from 'react-helmet';
import Author from "components/Author";
import NavPage from "components/NavPage";
import { FontPoster } from "components/FontPoster";
import { FontTestDrive } from "components/FontTestDrive";

import { BuyFont } from "components/BuyFont";
import { FontDescription } from "components/FontDescription";

import { fonts } from "data";
import { getTextTransform } from "dataUtils";

import "./fontDetail.scss";

export function FontDetail({ match }) {
    useEffect(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    });

    const font = fonts.find((entry) => {
        return entry.font_name === match.params.font_name;
    });

    const fontFamily = `${font.font_family}, Helvetica`;
    const fontSize = font.size_xlarge;
    const textTransform = `${getTextTransform(font.font_supported_case)}`;
    const top = font.offset_top_xlarge || '0';
    const fontTestDriveInputStyles = {
        textAlign: 'center',
    };

    return (
        <NavPage headerGradientOnly={true}>
            <Helmet>
                <title>TA NA KA Typeclub: {font.font_name}</title>
                <meta name="description" content={font.description} />
            </Helmet>
            <div className="fontDetailPage">
                <div className="header">
                    <div className="fontName" style={{ fontFamily, fontSize, textTransform, top }}>
                        {font.font_name}
                    </div>
                    <Author font={font} showLinks={true} />
                </div>
                {/* TODO: test drive slider custom styles */}
                <FontTestDrive defaultSize={'max'} inputStyles={fontTestDriveInputStyles} font={font} className="testDrive" />
                <FontDescription font={font} className="description" />
                <BuyFont font={font} className="buyFont" />
                <div className="licensing">
                    <h5 className="aboutLicensesHeading">About Licenses</h5>
                    <ul className="aboutLicenses">
                        <li><a href="../downloads/void_license_personal.pdf">Personal</a></li>
                        <li><a href="../downloads/void_license_commercial.pdf">Commercial</a></li>
                    </ul>
                </div>
                <FontPoster font={font} className="poster" />
            </div>
        </NavPage>
    );
}