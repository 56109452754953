import React from "react";
import { FontList } from "components/FontList";
import NavPage from "components/NavPage";
import { Helmet } from 'react-helmet';

import './Fonts.scss';

export function Fonts() {
    return (
        <NavPage headerGradientOnly={true}>
            <Helmet>
                <title>TA NA KA Typeclub: Fonts</title>
                <meta name="description" content="Tanaka Typeclub's list of fonts made by independent creators. Browse and test drive fonts." />
            </Helmet>
            <div className="fontsPageContent">
                <FontList />
            </div>
        </NavPage>
    );
}