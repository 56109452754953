import React from "react";
import { Background } from "./Background";
import { Header } from "./Header";
import { Footer } from "./Footer";

import './NavPage.scss';

export class NonNavPage extends React.Component {
    render() {
        return (
            <div className="navPage">
                <Background />
                <Header hasNav={false} />
                    <div className="pageBody">
                        {this.props.children}
                    </div>
                <Footer />
            </div>
        );
    }
};