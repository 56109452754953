import React from "react";
import { Link } from "react-router-dom";

import AuthorLinks from "components/AuthorLinks";

import './ContributorsList.scss';

import { fonts } from "data";
import { getAuthors, byLastName } from "dataUtils";

export class ContributorsList extends React.Component {
    // Handles multiple fonts for the same author
    authorFonts(fonts) {
        const label = fonts.length > 1 ? "Fonts: " : "Font: ";
        const links = fonts.map((font, index) => {
            const isLastFont = index + 1 >= fonts.length;
            const separator = isLastFont ? "" : ", ";
            const { font_name: fontName } = font;
            const fontDetailPage = `/font/${fontName}`;
            return (
                <React.Fragment key={fontName}>
                    <Link className="fontName" to={fontDetailPage}>{fontName}</Link>{separator}
                </React.Fragment>
            );
        });
        return (
            <span className="authorFonts">
                {label} {links}
            </span>
        );
    }
    
    list() {
        const authors = getAuthors(fonts).sort(byLastName);
        return authors.map((author) => {
            const backgroundImage = `url(../bios/${author.author_bio_image})`;
            return (
                <div key={author.author_name_full} className="author">
                    <div className="authorImage" style={{backgroundImage}}></div>
                    <div className="authorInfo">
                        <span className="authorName">
                            {author.author_name_full}
                        </span>
                        {this.authorFonts(author.all_fonts)}
                        <AuthorLinks font={author} showContact={true} />
                        <span className="authorBio">
                            {author.author_bio_text}
                        </span>
                    </div>
                </div>
            );
        });
    }
    
    render() {
        return (
            <div className="authorsList">
                <h2>Contributors</h2>
                {/* <div className="authorsListDescription">
                    This is a list of authors for the different fonts offered on Tanaka Typeclub.
                </div> */}
                <div className="authorsGrid">
                    {this.list()}
                </div>
            </div>
        );
    }
};