import React, { useState, useEffect } from "react";
import "./FontTestDrive.scss"

const SIZE_MIN = 8;
const SIZE_MAX = 100;
const SIZE_STEP = 2;

export function FontTestDrive({ font, defaultSize, config, inputStyles = {}, textContent, ...props }) {
    const [text, setText] = useState(window.testDriveText || "Type here...");
    const [size, setSize] = useState(parseInt(font.size_xlarge));

    useEffect(() => {
        if (defaultSize === 'max') {
            setSize(SIZE_MAX);
        }
    }, [defaultSize]);

    useEffect(() => {
        if (textContent) {
            setText(textContent);
        }
    }, [textContent]);

    function handleTextChange(e) {
        setText(e.target.value);
        window.testDriveText = e.target.value;
        if (config && config.onTextChange) {
            config.onTextChange(e);
        }
    }

    function handleSizeChange(e) {
        setSize(e.target.value);
    }

    function getTextTransform(supportedCase) {
        switch(supportedCase) {
            case "lower":
                return "lowercase";
            case "all":
                return "none";
            case "upper":
                return "uppercase";
            default:
                return "none";
        }
    }

    const fontFamily = `${font.font_family}, Helvetica`;
    const fontSize = `${size}px`;
    const textTransform = getTextTransform(font.font_supported_case);

    const classNameFromParent = props.className ? `${props.className} ` : '';

    if (config && config.basic) {
        return (
            <div className={`${classNameFromParent}fontTestDriveBasic`}>
                <div className="sizeSelector">
                    <input 
                        type="range"
                        min={SIZE_MIN}
                        max={SIZE_MAX}
                        value={size}
                        step={SIZE_STEP}
                        id="size"
                        onChange={handleSizeChange}
                    />
                    <label htmlFor="size" className="sizeSliderLabel">Size</label>
                </div>
                <input 
                    type="text"
                    className="testDriveText"
                    value={text}
                    onChange={handleTextChange}
                    style={{fontFamily, fontSize, textTransform}} 
                />
            </div>
        );
    }

    return (
        <div className={`${classNameFromParent}fontTestDrive`}>
            <div className="sizeSelector">
                <input 
                    type="range"
                    min={SIZE_MIN}
                    max={SIZE_MAX}
                    value={size}
                    step={SIZE_STEP}
                    id="size"
                    onChange={handleSizeChange}
                />
                <label htmlFor="size" className="sizeSliderLabel">Size</label>
            </div>
            <input 
                type="text"
                className="testDriveText"
                value={text}
                onChange={handleTextChange}
                style={{fontFamily, fontSize, textTransform, ...inputStyles}} 
            />
        </div>
    );
}