import React from "react";
import { DownloadZip } from "components/DownloadZip";
import { licenseLookup } from "../data";
import { redirectToDonation } from 'util/paypalUtils';

import './FontDownloadTile.scss';
import '../views/Thanks.scss';

export function FontDownloadTile({ font, license }) {    
    const { font_name: fontName } = font;
    const licenseDescription = licenseLookup[license].description;
    const zipLicense = licenseLookup[license].zip;

    return (
        <div className="thanksTile">
            <h2>Thanks!</h2>
            <p>
                Your {fontName}
                <span className="licenseDescription"> {licenseDescription} </span>
                package will begin downloading momentarily.
            </p>
            <p>
                Thank you for supporting independent type creators. You can feel good knowing that
                100% of your contribution went directly to the designer - Tanaka Type Club takes no cut.

                At Tanaka Type Club, we rely on donations from people like you to keep this operation running.

                Can you spare an extra dollar or two to support our mission of providing great independent 
                typefaces?
                <br />
                <br />

                    <button 
                    type="button" 
                    className="btn btn-info tanakaDonateButton"
                    onClick={() => redirectToDonation()}
                >
                    Donate to support Tanaka Type Club
                </button>
            </p>
            <DownloadZip font={font} license={zipLicense} />
        </div>
    );
};